import React from 'react';

import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import {
  store
} from './redux/rootStorage';
import reportWebVitals from './reportWebVitals';
import './redux/reactotron';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router >
        <App/>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
