import Container from './Container';


const TokenScreen = ()=> {
  return (
    <Container />
  );
};


export default TokenScreen;
