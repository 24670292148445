import { createReduxName } from 'utils/redux';

export const reduxName = createReduxName('APP/FAVOURITES');

export const
  GET_FAVOURITES_BEGIN = `${reduxName}/GET_FAVOURITES_BEGIN`,
  GET_FAVOURITES_SUCCESS = `${reduxName}/GET_FAVOURITES_SUCCESS`,
  GET_FAVOURITES_FAILURE = `${reduxName}/GET_FAVOURITES_FAILURE`;

export const SAVE_FAVOURITES_BEGIN = `${reduxName}/SAVE_FAVOURITES_BEGIN`,
  SAVE_FAVOURITES_SUCCESS = `${reduxName}/SAVE_FAVOURITES_SUCCESS`,
  SAVE_FAVOURITES_FAILURE = `${reduxName}/SAVE_FAVOURITES_FAILURE`;

export const REMOVE_FAVOURITES_BEGIN = `${reduxName}/REMOVE_FAVOURITES_BEGIN`,
  REMOVE_FAVOURITES_SUCCESS = `${reduxName}/REMOVE_FAVOURITES_SUCCESS`,
  REMOVE_FAVOURITES_FAILURE = `${reduxName}/REMOVE_FAVOURITES_FAILURE`;
