import { createReduxName } from 'utils/redux';

export const reduxName = createReduxName('ENS');

export const SEARCH_DOMAIN_BEGIN = `${reduxName}/SEARCH_DOMAIN_BEGIN`,
  SEARCH_DOMAIN_SUCCESS = `${reduxName}/SEARCH_DOMAIN_SUCCESS`,
  SEARCH_DOMAIN_FAILURE = `${reduxName}/SEARCH_DOMAIN_FAILURE`;

export const GET_RENT_PRICES_BEGIN = `${reduxName}/GET_RENT_PRICES_BEGIN`,
  GET_RENT_PRICES_SUCCESS = `${reduxName}/GET_RENT_PRICES_SUCCESS`,
  GET_RENT_PRICES_FAILURE = `${reduxName}/GET_RENT_PRICES_FAILURE`;

export const COMMIT_BEGIN = `${reduxName}/COMMIT_BEGIN`,
  COMMIT_REQUEST_START = `${reduxName}/COMMIT_REQUEST_START`,
  COMMIT_SUCCESS = `${reduxName}/COMMIT_SUCCESS`,
  COMMIT_FAILURE = `${reduxName}/COMMIT_FAILURE`;

export const REGISTER_BEGIN = `${reduxName}/REGISTER_BEGIN`,
  REGISTER_SUCCESS = `${reduxName}/REGISTER_SUCCESS`,
  REGISTER_FAILURE = `${reduxName}/REGISTER_FAILURE`;

export const CHANGE_ADDRESS_BEGIN = `${reduxName}/CHANGE_ADDRESS_BEGIN`,
  CHANGE_ADDRESS_SUCCESS = `${reduxName}/CHANGE_ADDRESS_SUCCESS`,
  CHANGE_ADDRESS_FAILURE = `${reduxName}/CHANGE_ADDRESS_FAILURE`;

export const TRANSFER_DOMAIN_BEGIN = `${reduxName}/TRANSFER_DOMAIN_BEGIN`,
  TRANSFER_DOMAIN_SUCCESS = `${reduxName}/TRANSFER_DOMAIN_SUCCESS`,
  TRANSFER_DOMAIN_FAILURE = `${reduxName}/TRANSFER_DOMAIN_FAILURE`;

export const RENEW_BEGIN = `${reduxName}/RENEW_BEGIN`,
  RENEW_SUCCESS = `${reduxName}/RENEW_SUCCESS`,
  RENEW_FAILURE = `${reduxName}/RENEW_FAILURE`;

export const GET_TOKEN_SALE_DATA_BEGIN = `${reduxName}/GET_TOKEN_SALE_DATA_BEGIN`,
  GET_TOKEN_SALE_DATA_SUCCESS = `${reduxName}/GET_TOKEN_SALE_DATA_SUCCESS`,
  GET_TOKEN_SALE_DATA_FAILURE = `${reduxName}/GET_TOKEN_SALE_DATA_FAILURE`;

export const BUY_TOKEN_BEGIN = `${reduxName}/BUY_TOKEN_BEGIN`,
  BUY_TOKEN_SUCCESS = `${reduxName}/BUY_TOKEN_SUCCESS`,
  BUY_TOKEN_FAILURE = `${reduxName}/BUY_TOKEN_FAILURE`;

export const GET_TOKEN_PRICE_BEGIN = `${reduxName}/GET_TOKEN_PRICE_BEGIN`,
  GET_TOKEN_PRICE_SUCCESS = `${reduxName}/GET_TOKEN_PRICE_SUCCESS`,
  GET_TOKEN_PRICE_FAILURE = `${reduxName}/GET_TOKEN_PRICE_FAILURE`;

export const GET_TOKEN_FOR_MORE_TOKENS_DATA_BEGIN = `${reduxName}/GET_TOKEN_FOR_MORE_TOKENS_DATA_BEGIN`,
  GET_TOKEN_FOR_MORE_TOKENS_DATA_SUCCESS = `${reduxName}/GET_TOKEN_FOR_MORE_TOKENS_DATA_SUCCESS`,
  GET_TOKEN_FOR_MORE_TOKENS_DATA_FAILURE = `${reduxName}/GET_TOKEN_FOR_MORE_TOKENS_DATA_FAILURE`;

export const CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_BEGIN = `${reduxName}/CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_BEGIN`,
  CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_SUCCESS = `${reduxName}/CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_SUCCESS`,
  CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_FAILURE = `${reduxName}/CHECK_ALLOWANCE_TOKEN_FOR_MORE_TOKENS_FAILURE`;

export const APPROVE_TOKEN_FOR_MORE_TOKENS_BEGIN = `${reduxName}/APPROVE_TOKEN_FOR_MORE_TOKENS_BEGIN`,
  APPROVE_TOKEN_FOR_MORE_TOKENS_SUCCESS = `${reduxName}/APPROVE_TOKEN_FOR_MORE_TOKENS_SUCCESS`,
  APPROVE_TOKEN_FOR_MORE_TOKENS_FAILURE = `${reduxName}/APPROVE_TOKEN_FOR_MORE_TOKENS_FAILURE`;

export const STAKE_TOKEN_FOR_MORE_TOKENS_BEGIN = `${reduxName}/STAKE_TOKEN_FOR_MORE_TOKENS_BEGIN`,
  STAKE_TOKEN_FOR_MORE_TOKENS_SUCCESS = `${reduxName}/STAKE_TOKEN_FOR_MORE_TOKENS_SUCCESS`,
  STAKE_TOKEN_FOR_MORE_TOKENS_FAILURE = `${reduxName}/STAKE_TOKEN_FOR_MORE_TOKENS_FAILURE`;

export const REMOVE_TOKEN_FOR_MORE_TOKENS_BEGIN = `${reduxName}/REMOVE_TOKEN_FOR_MORE_TOKENS_BEGIN`,
  REMOVE_TOKEN_FOR_MORE_TOKENS_SUCCESS = `${reduxName}/REMOVE_TOKEN_FOR_MORE_TOKENS_SUCCESS`,
  REMOVE_TOKEN_FOR_MORE_TOKENS_FAILURE = `${reduxName}/REMOVE_TOKEN_FOR_MORE_TOKENS_FAILURE`;